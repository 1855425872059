import React from "react";
import Header from "./Components/Header";
import SalaryProgress from "./Components/SalaryProgress";
import EarningsSection from "./EarningsSection/EarningsSection";
import DidNotPlayEquivalent from "./EarningsSection/Did Not Play Box/DidNotPlayEquivalent";
import GamesSummary from "./Components/GamesSummary";
import InjuryList from "./Components/InjuryList/InjuryList";
import "./App.css";
import HeadsUpAlert from "./Components/HeadsUpAlert";

function App() {
  return (
    <div className="App">
      <Header />
      <SalaryProgress />
      <HeadsUpAlert />
      <div className="content-container">
        <GamesSummary />
      </div>
      <EarningsSection />
      <InjuryList />
      <DidNotPlayEquivalent />
    </div>
  );
}

export default App;
