import React, { useState } from "react";
import InjuryRecord from "../InjuryRecord/InjuryRecord";
import "./InjuryRecordList.css";

const InjuryRecordList = () => {
  const [showModal, setShowModal] = useState(false);

  const injuries = [
    {
      injury: "Oblique",
      duration: "20",
      type: "Strain",
      link: "https://www.cbssports.com/fantasy/baseball/news/angels-anthony-rendon-unlikely-to-play-again-in-2024/",
      ratingLabel: "Team eliminated = go home early",
      rating: 1,
      dateRange: "9/10/24 - 9/30/24",
    },

    {
      injury: "Hamstring",
      duration: "68",
      type: "Pull",
      link: "https://www.mlb.com/news/anthony-rendon-hamstring-injury",
      ratingLabel: "That didn't take long",
      rating: 2,
      dateRange: "4/21/24 - 7/8/24",
    },

    {
      injury: "Lower back",
      duration: "6",
      type: "Soreness",
      link: "https://www.si.com/mlb/angels/angels-news/angels-anthony-rendon-doesn-t-expect-to-be-on-injured-list-too-long-01j47zxhpgd9",
      ratingLabel: "Golf trip with the boyz",
      rating: 1,
      dateRange: "7/27/24 - 8/6/24",
    },
  ];

  return (
    <div className="content-section">
      <div className="section-header">
        <h2 className="section-title">
          Injury History
          <span className="info-icon" onClick={() => setShowModal(true)}>
            ⓘ
          </span>
        </h2>
      </div>

      {showModal && (
        <div className="modal-overlay" onClick={() => setShowModal(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h3>Rating System Explained</h3>
            <p>
              Each injury is rated on a scale of 0-5 based on how valid or
              legitimate of an injury it was. <br />
              <br />
              <b>
                Most of Tony 10-games' IL trips are because, in his words,
                <br /> <br />
                <i>"There are too many dang games in a season."</i>
              </b>
            </p>
            <button onClick={() => setShowModal(false)}>Close</button>
          </div>
        </div>
      )}

      <div className="injury-grid">
        {injuries.map((injury, index) => (
          <InjuryRecord
            key={index}
            injury={injury.injury}
            duration={injury.duration}
            type={injury.type}
            link={injury.link}
            ratingLabel={injury.ratingLabel}
            rating={injury.rating}
            dateRange={injury.dateRange}
          />
        ))}
      </div>
    </div>
  );
};

export default InjuryRecordList;
