import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

export const GameDataContext = createContext();

export const GameDataProvider = ({ children }) => {
  // Stats
  const hits = 199;
  const atBats = 795;
  const hr = 22;
  const rbi = 114;
  const r = 100;
  const bwar = 3.5;
  const fwar = 3.9;

  const [dollarsPerHit, setDollarsPerHit] = useState(0);
  const [dollarsPerAB, setDollarsPerAB] = useState(0);
  const [dollarsPerHR, setDollarsPerHR] = useState(0);
  const [dollarsPerRBI, setDollarsPerRBI] = useState(0);
  const [dollarsPerRun, setDollarsPerRun] = useState(0);
  const [dollarsPerBWAR, setDollarsPerBWAR] = useState(0);
  const [dollarsPerFWAR, setDollarsPerFWAR] = useState(0);

  // Games before this year
  const rendonPlayedGamesPre24 = 200;
  const angelsPlayedGamesPre24 = 546;

  // Earnings
  const rendonEarningsPre24 = 129285714;
  const salaryFor2024 = 38571428;
  const totalContractValue = 245000000;
  const totalSalary2024 = 38571428;
  const secondsInAYear = 365 * 24 * 60 * 60;
  const wagePerSecond2024 = totalSalary2024 / secondsInAYear;
  const wagePerScheduleGame2024 = totalSalary2024 / 162;

  // Rendon games played this year
  const [rendon2024GamesPlayedAmount, setRendon2024GamesPlayedAmount] =
    useState(19);

  // Total games Rendon has played as Angel
  const [rendonTotalGamesAsAngel, setRendonTotalGamesAsAngel] = useState(
    rendonPlayedGamesPre24 + rendon2024GamesPlayedAmount
  );

  // Games Angels played since signing Rendon
  const [gamesAngelsPlayedInRendonEra, setGamesAngelsPlayedInRendonEra] =
    useState(angelsPlayedGamesPre24);

  // Games Angels played in 2024
  const [gamesAngelsPlayed2024, setGamesAngelsPlayed2024] = useState(0);

  // Amount Rendon has earned per game he has played (Total taken so far / Total games played)
  const [earningsPerGame, setEarningsPerGame] = useState(0);

  // Amount Rendon has earned in 2024 to date
  const [current2024Earnings, setCurrent2024Earnings] = useState(0);

  // Amount taken from Angels all time
  const [takenFromAngels, setTakenFromAngels] = useState(0);

  // Games missed and amount
  const [gamesMissed, setGamesMissed] = useState(
    angelsPlayedGamesPre24 - rendonPlayedGamesPre24
  );

  // Amount Rendon has made just from games he has not played in
  const [amountFromMissed, setAmountFromMissed] = useState(
    gamesMissed * wagePerScheduleGame2024
  );

  const [seasonCompletionPercentage, setSeasonCompletionPercentage] =
    useState(0);

  const calculateRendonEarningsForOnly2024 = (
    angelsGamesPlayed,
    rendonSalary2024
  ) => {
    const totalSeasonGames = 162;
    const completionPercentage = angelsGamesPlayed / totalSeasonGames;
    setSeasonCompletionPercentage(completionPercentage);
    return completionPercentage * rendonSalary2024;
  };

  useEffect(() => {
    const fetchRendonGames = async () => {
      try {
        const response = await axios.get(
          "https://statsapi.mlb.com/api/v1/people/543685?hydrate=stats(group=[hitting],type=season,sportId=1)"
        );
        const gamesPlayed =
          response.data.people[0].stats[0].splits[0].stat.gamesPlayed;
        // Update rendonTotalGamesAsAngel with the new total games played
        setRendonTotalGamesAsAngel(gamesPlayed + rendonPlayedGamesPre24);
        // Update rendon2024GamesPlayedAmount with the new games played in 2024
        setRendon2024GamesPlayedAmount(gamesPlayed);
      } catch (error) {
        console.error("Error fetching Rendon's games data:", error);
      }
    };

    const fetchAngelsGames = async () => {
      try {
        const response = await axios.get(
          "https://statsapi.mlb.com/api/v1/standings?leagueId=103"
        );
        const records = response.data.records;
        let gamesPlayed = 0;

        for (const record of records) {
          for (const teamRecord of record.teamRecords) {
            if (teamRecord.team.id === 108) {
              gamesPlayed = teamRecord.gamesPlayed;
              break;
            }
          }
        }

        // Update gamesAngelsPlayedInRendonEra with the new total games played in Rendon's era
        setGamesAngelsPlayedInRendonEra(gamesPlayed + angelsPlayedGamesPre24);
        // Update gamesAngelsPlayed2024 with the new games played in 2024
        setGamesAngelsPlayed2024(gamesPlayed);
      } catch (error) {
        console.error("Error fetching Angels' games data:", error);
      }
    };

    const fetchData = async () => {
      await Promise.all([fetchRendonGames(), fetchAngelsGames()]);

      const rendonEarningsFor2024 = calculateRendonEarningsForOnly2024(
        gamesAngelsPlayed2024, // Change this from gamesAngelsPlayedInRendonEra
        salaryFor2024
      );

      // Calculate the total amount taken from Angels
      const totalTakenFromAngels = rendonEarningsPre24 + rendonEarningsFor2024;
      setTakenFromAngels(totalTakenFromAngels);

      // Update current2024Earnings with the new earnings for 2024
      setCurrent2024Earnings(rendonEarningsFor2024);

      // Calculate the amount taken from Angels
      const updatedEarningsPerGameAsAngel =
        totalTakenFromAngels / rendonTotalGamesAsAngel;
      // Update earningsPerGame with the new earnings per game
      setEarningsPerGame(updatedEarningsPerGameAsAngel);

      // Calculate games missed and amount
      const missedGames =
        gamesAngelsPlayedInRendonEra - rendonTotalGamesAsAngel;
      // Update gamesMissed with the new number of games missed
      setGamesMissed(missedGames);
      // Update amountFromMissed with the new amount from missed games
      setAmountFromMissed(missedGames * wagePerScheduleGame2024);

      // Update dollarsPer_ stats
      setDollarsPerHit(totalTakenFromAngels / hits);
      setDollarsPerAB(totalTakenFromAngels / atBats);
      setDollarsPerHR(totalTakenFromAngels / hr);
      setDollarsPerRBI(totalTakenFromAngels / rbi);
      setDollarsPerRun(totalTakenFromAngels / r);
      setDollarsPerBWAR(totalTakenFromAngels / bwar);
      setDollarsPerFWAR(totalTakenFromAngels / fwar);
    };

    fetchData();
  }, [
    gamesAngelsPlayedInRendonEra,
    rendonTotalGamesAsAngel,
    gamesAngelsPlayed2024,
    wagePerScheduleGame2024,
  ]);

  return (
    <GameDataContext.Provider
      value={{
        rendonTotalGamesAsAngel,
        gamesAngelsPlayedInRendonEra,
        current2024Earnings,
        earningsPerGame,
        takenFromAngels,
        gamesMissed,
        amountFromMissed,
        dollarsPerHit,
        dollarsPerAB,
        dollarsPerHR,
        dollarsPerRBI,
        dollarsPerRun,
        dollarsPerBWAR,
        dollarsPerFWAR,
        totalContractValue,
        totalSalary2024,
        secondsInAYear,
        wagePerSecond2024,
        rendon2024GamesPlayedAmount,
        gamesAngelsPlayed2024,
        wagePerScheduleGame2024,
        earnedPerSecond: wagePerSecond2024,
        seasonCompletionPercentage,
      }}
    >
      {children}
    </GameDataContext.Provider>
  );
};
